import { useState } from "react";
import { GoogleSignIn } from "./GoogleSignIn.tsx";
import { Register } from "./Register.tsx";
import { LoginForm } from "./LoginForm.tsx";

export function Login() {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <>
      <div className="section columns">
        <div className="column"></div>
        <div className="column">
          <div className="box has-text-centered">
            <div className="tabs is-centered is-toggle">
              <ul>
                <li className={isLogin ? "is-active" : ""}>
                  <a onClick={() => setIsLogin(true)}>
                    <span>Login</span>
                  </a>
                </li>
                <li className={!isLogin ? "is-active" : ""}>
                  <a onClick={() => setIsLogin(false)}>
                    <span>Sign Up</span>
                  </a>
                </li>
              </ul>
            </div>

            {isLogin ? <LoginForm /> : <Register />}

            <hr />
            <p>or</p>
            <GoogleSignIn />
            <p className="help has-text-grey-light is-medium">
              After signing in, you may be asked to enter your company
              information if not done previously.
            </p>
          </div>
        </div>

        <div className="column"></div>
      </div>
      <footer className="footer paddingless has-background-white">
        <div className="content has-text-centered">
          <p>
            <a href="https://massiverocket.com/privacy-policy" target="_blank">
              Privacy
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}
