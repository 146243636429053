import { ChangeEvent, ReactNode } from "react";

interface Props {
  label?: string;
  name: string;
  value?: string;
  help?: string | { __html: string };
  defaultValue?: string;
  children: ReactNode | ReactNode[];
  required?: boolean;
  iconLeft?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export default function AppSelect({
  label,
  children,
  iconLeft,
  help,
  defaultValue,
  ...rest
}: Props) {
  return (
    <div className="field">
      {label && (
        <label className="label">
          <span>{label}</span>
          {rest.required && <span className="has-text-danger"> *</span>}
        </label>
      )}
      <div className={`control ${iconLeft ? "has-icons-left" : ""}`}>
        {iconLeft && (
          <span className="icon is-small is-left">
            <i className={`fa ${iconLeft}`}></i>
          </span>
        )}
        <div className="select is-fullwidth">
          <select defaultValue={defaultValue} {...rest}>
            {children}
          </select>
        </div>
      </div>
      {typeof help === "string" && help && <p className="help">{help}</p>}
      {typeof help !== "string" && help && (
        <p className="help" dangerouslySetInnerHTML={help}></p>
      )}
    </div>
  );
}
