import "../styles/loading.css";

export function Loading() {
  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-mobile is-centered">
            <div className="column"></div>
            <div className="column is-narrow">
              <div id="loading"></div>
            </div>
            <div className="column"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
