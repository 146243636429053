import { FormEvent, useContext, useEffect, useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  AuthError,
} from "firebase/auth";
import { toast } from "react-toastify";
import { useNavigate } from "@tanstack/react-router";
import { AuthContext } from "../../helpers/authContext.tsx";
import { updateUserLoginData } from "../../helpers/firebase.tsx";

export function LoginForm() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);

  useEffect(() => {
    if (user) {
      void navigate({ to: "/" });
    }
  }, [user, navigate]);

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;
      toast.success(`Welcome ${user.displayName}!`);
      await updateUserLoginData(user.uid);
    } catch (error) {
      const authError = error as AuthError;
      toast.error(`Error: ${authError.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setResetLoading(true);
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      toast.info("Password reset email sent. Please check your inbox.");
    } catch (error) {
      const authError = error as AuthError;
      toast.error(`Error: ${authError.message}`);
    } finally {
      setResetLoading(false);
    }
  };

  return (
    <form onSubmit={showResetForm ? handlePasswordReset : handleLogin}>
      <div className="field">
        <label className="label has-text-left">
          <span>Email</span> <span className="has-text-danger"> *</span>
        </label>
        <div className="control">
          <input
            className="input"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
      </div>
      {!showResetForm && (
        <div className="field">
          <label className="label has-text-left">
            <span>Password</span> <span className="has-text-danger"> *</span>
          </label>
          <div className="control">
            <input
              className="input"
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </div>
      )}
      <button
        className="button is-primary has-text-white"
        type="submit"
        disabled={loading || resetLoading}
      >
        {loading
          ? "Logging in..."
          : resetLoading
            ? "Sending..."
            : showResetForm
              ? "Send Reset Link"
              : "Login"}
      </button>
      {!showResetForm ? (
        <div className="field pt-2">
          <p className="has-text-right">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowResetForm(true);
              }}
            >
              Forgot Password?
            </a>
          </p>
        </div>
      ) : (
        <div className="field pt-2">
          <p className="has-text-right">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowResetForm(false);
              }}
            >
              Back to Login
            </a>
          </p>
        </div>
      )}
    </form>
  );
}
