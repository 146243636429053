import { createFileRoute, redirect } from "@tanstack/react-router";
import VerifyEmail from "../components/auth/VerifyEmail.tsx";

export const Route = createFileRoute("/confirm-account")({
  beforeLoad: ({ context }) => {
    if (!context.auth.user) {
      throw redirect({
        to: "/login",
      });
    }
    if (context.auth.user.emailVerified) {
      throw redirect({
        to: "/",
      });
    }
  },
  component: () => <VerifyEmail />,
});
