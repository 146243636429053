import { FormEvent, useState } from "react";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../helpers/firebase.tsx";
import { toast } from "react-toastify";
import AppInput from "../../AppInput.tsx";
import AppSelect from "../../AppSelect.tsx";
import { useNavigate } from "@tanstack/react-router";

export function Register() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");

  const handleClassicRegistration = async (
    event: FormEvent<HTMLFormElement>,
  ) => {
    setLoading(true);
    const form = event.target as HTMLFormElement;
    event.preventDefault();
    const formData = new FormData(form);

    const firstName = formData.get("firstName") as string;
    const lastName = formData.get("lastName") as string;
    const company = formData.get("company") as string;
    const companySize = formData.get("companySize") as string;
    const businessEmail = formData.get("businessEmail") as string;
    const password = formData.get("password") as string;
    const confirmPassword = formData.get("confirmPassword") as string;

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      setPasswordMessage("Passwords do not match");
      setLoading(false);
      return;
    } else {
      setPasswordMessage("");
    }

    if (businessEmail.trim().endsWith("@massiverocket.com")) {
      toast.error(
        "Please use 'Sign in with Google' button for your Massive Rocket account.",
      );
      setLoading(false);
      return;
    }

    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        businessEmail,
        password,
      );
      const user = result.user;
      await sendEmailVerification(user, { url: window.location.origin });

      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      });

      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        company,
        companySize,
        email: businessEmail,
        created_at: new Date(),
      });

      toast.success("Registration successful!");
      await navigate({ to: "/" });
    } catch (error) {
      toast.error(`${(error as Error).message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="has-text-left" onSubmit={handleClassicRegistration}>
      <AppInput
        label="First Name"
        name="firstName"
        maxLength={255}
        required={true}
        placeholder="Enter your first name"
      />
      <AppInput
        label="Last Name"
        name="lastName"
        maxLength={255}
        required={true}
        placeholder="Enter your last name"
      />
      <AppInput
        label="Business Email"
        name="businessEmail"
        maxLength={255}
        type="email"
        required={true}
        placeholder="Enter your business email"
      />
      <AppInput
        label="Company"
        name="company"
        maxLength={255}
        placeholder="Enter your company name"
        required={true}
      />

      <AppSelect name="companySize" required={true} label="Company Size">
        <option value="">Select company size</option>
        <option value="1-10">1-10</option>
        <option value="10-25">10-25</option>
        <option value="25-50">25-50</option>
        <option value="50-100">50-100</option>
        <option value="100-200">100-200</option>
        <option value="200-500">200-500</option>
        <option value="500-1000">500-1000</option>
      </AppSelect>

      <AppInput
        label="Password"
        name="password"
        type="password"
        placeholder="Choose your password"
        help="Must be at least 8 characters long and include letters, numbers, and special characters."
        pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
        title="Must be at least 8 characters long and include letters, numbers, and special characters"
        required={true}
      />

      <AppInput
        label="Confirm Password"
        name="confirmPassword"
        type="password"
        placeholder="Confirm your password"
        pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
        title="Must be at least 8 characters long and include letters, numbers, and special characters"
        help={passwordMessage}
        required={true}
      />

      <div className="has-text-centered">
        <button
          className="button is-primary has-text-white"
          type="submit"
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
}
