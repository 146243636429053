import { useContext, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { AuthContext } from "./helpers/authContext.tsx";
import { Link, useNavigate } from "@tanstack/react-router";

export function UserMenu() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const auth = getAuth();

  useEffect(() => {
    if (!user) {
      void navigate({ to: "/login" });
    }
  }, [user, navigate]);

  const onLogOut = async () => {
    await auth.signOut();
  };

  if (!user) {
    return null;
  } else {
    return (
      <div className="navbar-item has-dropdown is-hoverable pr-3">
        <a className="navbar-link has-text-white">{user.displayName}</a>

        <div className="navbar-dropdown">
          {!user.email?.endsWith("@massiverocket.com") && (
            <Link to="/profile" className="navbar-item">
              Profile
            </Link>
          )}
          <a className="navbar-item" onClick={onLogOut}>
            Log out
          </a>
        </div>
      </div>
    );
  }
}
