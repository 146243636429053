import { Link } from "@tanstack/react-router";

export default function PageNotFound() {
  return (
    <section className="section is-medium">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column has-text-centered">
            <h1 className="title">404 Page Not Found</h1>
            <p className="subtitle">This page doesn't exist.</p>
            <Link to={"/"} className="button">
              Home
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
