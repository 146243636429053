import { lazy, Suspense } from "react";
import { Loading } from "../Loading.tsx";

const Braze = lazy(() => import("./Braze.tsx"));

export default function Index() {
  return (
    <Suspense fallback={<Loading />}>
      <Braze />
    </Suspense>
  );
}
