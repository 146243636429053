import logo from "./assets/logo.png";
import Troubleshooting from "./Troubleshooting.tsx";
import { UserMenu } from "./UserMenu.tsx";
import { useContext } from "react";
import { AuthContext } from "./helpers/authContext.tsx";
import { Link } from "@tanstack/react-router";

export default function NavBar() {
  const { userData } = useContext(AuthContext);

  return (
    <nav
      className="navbar is-fixed-top is-danger"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a
          href="https://massiverocket.com/"
          className="navbar-item"
          target="_blank"
        >
          <img src={logo} alt="Massive Rocket Logo" className="navbar-logo" />
        </a>
        <div className="navbar-item">
          <span className="has-text-weight-bold has-text-white">
            Braze Playground
          </span>
          <span className="tag is-light">BETA</span>
        </div>
      </div>
      <div className="navbar-menu">
        <div className="navbar-start">
          <Link to="/" className="navbar-item has-text-white">
            Home
          </Link>
          {userData?.admin && (
            <Link to="/users" className="navbar-item has-text-white">
              Admin
            </Link>
          )}
          <a
            href="https://massiverocket.com/actionable-me"
            className="navbar-item has-text-white"
            target="_blank"
            title="Braze Acceleration Engine"
          >
            <span>actionable.me</span>
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          </a>
        </div>
        <div className="navbar-end">
          <Troubleshooting />
          <UserMenu />
        </div>
      </div>
    </nav>
  );
}
