import { AuthError, sendEmailVerification } from "firebase/auth";
import { toast } from "react-toastify";
import { AuthContext, User } from "../../helpers/authContext.tsx";
import { useContext, useEffect } from "react";
import { useNavigate } from "@tanstack/react-router";

export default function VerifyEmail() {
  const { user } = useContext<User>(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.emailVerified) {
      void navigate({ to: "/" });
    }
  }, [user, navigate]);

  const resentVerificationEmail = async () => {
    try {
      await sendEmailVerification(user!, { url: window.location.origin });
      toast.info("Verification email sent. Please check your inbox.");
    } catch (error) {
      const authError = error as AuthError;
      toast.error(`Error: ${authError.message}`);
    }
  };

  return (
    <div className="section">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">Thank you for registering!</p>
        </header>
        <div className="card-content">
          <div className="block">
            Please check your email inbox to verify your account. Refresh this
            page once it has been done.
          </div>
          <button
            className="button is-primary"
            onClick={resentVerificationEmail}
          >
            Resend verification email
          </button>
        </div>
      </div>
    </div>
  );
}
