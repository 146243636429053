import { createFileRoute, redirect } from "@tanstack/react-router";
import ProfileDetails from "../components/auth/ProfileDetails.tsx";

export const Route = createFileRoute("/profile")({
  beforeLoad: ({ context }) => {
    if (!context.auth.user) {
      throw redirect({
        to: "/login",
      });
    }
  },
  component: () => <ProfileDetails />,
});
