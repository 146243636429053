import { FormEvent, useContext, useState } from "react";
import AppInput from "../../AppInput.tsx";
import AppSelect from "../../AppSelect.tsx";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../helpers/firebase.tsx";
import { toast } from "react-toastify";
import { AuthContext } from "../../helpers/authContext.tsx";
import { Link } from "@tanstack/react-router";
import { updateProfile } from "firebase/auth";

function ProfileDetails() {
  const { user, userData, refreshUserData } = useContext(AuthContext);
  const [editMode, setEditMode] = useState<boolean>(false);

  if (!user) {
    return <div>No user details available.</div>;
  }

  if (!userData) {
    return null;
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    const form = event.target as HTMLFormElement;
    event.preventDefault();
    const formData = new FormData(form);
    const newData = {
      firstName: formData.get("firstName") as string,
      lastName: formData.get("lastName") as string,
      company: formData.get("company") as string,
      companySize: formData.get("companySize") as string,
    };
    await updateDoc(doc(db, "users", user.uid), newData);
    const displayName = `${newData.firstName} ${newData.lastName}`;
    await updateProfile(user, { displayName });
    await refreshUserData();
    setEditMode(false);
    toast("Profile updated successfully!", { type: "success" });
  };

  const { firstName, lastName, company, companySize, email } = userData;
  return (
    <div className="section">
      <div className="pb-3">
        <Link to="/" className="button is-light">
          <span className="icon">
            <i className="fas fa-arrow-left"></i>
          </span>
          <span>Back</span>
        </Link>
      </div>
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">Profile Details</p>
          <button
            className="card-header-icon"
            onClick={() => setEditMode(!editMode)}
          >
            <span>{editMode ? "Cancel" : "Edit"}</span>
          </button>
        </header>
        <div className="card-content">
          {!editMode && (
            <div className="columns is-multiline">
              <div className="column is-one-third">
                <div className="user-attribute">
                  <strong>First Name:</strong> {firstName || "N/A"}
                </div>
              </div>
              <div className="column is-one-third">
                <div className="user-attribute">
                  <strong>Last Name:</strong> {lastName || "N/A"}
                </div>
              </div>
              <div className="column is-one-third">
                <div className="user-attribute">
                  <strong>Email:</strong> {email}
                </div>
              </div>
              <div className="column is-one-third">
                <div className="user-attribute">
                  <strong>Company Name:</strong> {company || "N/A"}
                </div>
              </div>
              <div className="column is-one-third">
                <div className="user-attribute">
                  <strong>Company Size:</strong> {companySize || "N/A"}
                </div>
              </div>
            </div>
          )}
          {editMode && (
            <form onSubmit={onSubmit}>
              <div className="columns is-multiline">
                <div className="column is-one-third">
                  <AppInput
                    label="First Name"
                    name="firstName"
                    required={true}
                    defaultValue={firstName}
                  />
                </div>
                <div className="column is-one-third">
                  <AppInput
                    label="Last Name"
                    name="lastName"
                    required={true}
                    defaultValue={lastName}
                  />
                </div>
                <div className="column is-one-third">
                  <AppInput
                    label="Email"
                    name="email"
                    required={true}
                    disabled={true}
                    defaultValue={email}
                  />
                </div>
                <div className="column is-one-third">
                  <AppInput
                    label="Company"
                    name="company"
                    required={true}
                    defaultValue={company}
                  />
                </div>
                <div className="column is-one-third">
                  <AppSelect
                    label="Company Size"
                    name="companySize"
                    required={true}
                    defaultValue={companySize}
                  >
                    <option value="">Select company size</option>
                    <option value="1-10">1-10</option>
                    <option value="10-25">10-25</option>
                    <option value="25-50">25-50</option>
                    <option value="50-100">50-100</option>
                    <option value="100-200">100-200</option>
                    <option value="200-500">200-500</option>
                    <option value="500-1000">500-1000</option>
                  </AppSelect>
                </div>
              </div>
              <button type="submit" className="button is-primary">
                Submit
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileDetails;
