import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import NavBar from "../NavBar.tsx";
import { User } from "../helpers/authContext.tsx";

interface MyRouterContext {
  auth: User;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootComponent,
});

function RootComponent() {
  return (
    <>
      <NavBar />
      <div className="container">
        <Outlet />
      </div>
      <span className="tag powered-by">
        Powered by&nbsp;
        <a href="https://massiverocket.com">Massive Rocket</a>
        &nbsp;🚀
      </span>
    </>
  );
}
