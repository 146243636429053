import { createContext, ReactNode, useEffect, useState } from "react";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import app, { getUserData, UserData } from "./firebase.tsx";
import { User as FirebaseUser } from "firebase/auth";
import { Loading } from "../components/Loading.tsx";
import * as Sentry from "@sentry/browser";

export interface User {
  user: FirebaseUser | null;
  userData: UserData | null;
  refreshUserData: () => Promise<void>;
}

const auth = getAuth(app);
const userObj: User = {
  user: null,
  userData: null,
  refreshUserData: async () => {},
};
export const AuthContext = createContext(userObj);

export const AuthContextProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        Sentry.setUser({ id: user.uid });
        await refreshUserData();
        setUser(user);
      } else {
        setUserData(null);
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const refreshUserData = async () => {
    const data = await getUserData();
    setUserData(data);
  };

  return (
    <AuthContext.Provider value={{ user, userData, refreshUserData }}>
      {loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
