import { createFileRoute, redirect } from "@tanstack/react-router";
import Admin from "../Admin.tsx";

export const Route = createFileRoute("/users")({
  beforeLoad: ({ context }) => {
    if (!context.auth.user) {
      throw redirect({
        to: "/login",
      });
    }

    if (!context.auth.userData || !context.auth.userData.admin) {
      throw redirect({
        to: "/",
      });
    }
  },
  component: () => <Admin />,
});
