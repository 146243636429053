import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  doc,
  getDoc,
  getFirestore,
  increment,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

export interface UserData {
  admin?: boolean;
  company: string;
  companySize: string;
  email: string;
  firstName: string;
  lastName: string;
  created_at: Timestamp;
  lastLogin: Timestamp;
  loginCount: number;
}

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);

export async function getUserData(): Promise<UserData | null> {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    return null;
  }

  const userRef = doc(db, "users", currentUser.uid!);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data() as UserData;
  } else {
    return null;
  }
}

export const updateUserLoginData = async (userUid: string) => {
  const userDoc = doc(db, "users", userUid);
  await updateDoc(userDoc, {
    lastLogin: serverTimestamp(),
    loginCount: increment(1),
  });
};

export default app;
