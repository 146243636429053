import { createFileRoute, redirect } from "@tanstack/react-router";
import Index from "../components/braze";

export const Route = createFileRoute("/")({
  beforeLoad: ({ context }) => {
    if (!context.auth.user) {
      throw redirect({
        to: "/login",
      });
    }

    if (!context.auth.user.emailVerified) {
      throw redirect({
        to: "/confirm-account",
      });
    }

    if (
      !context.auth.user.email!.endsWith("@massiverocket.com") &&
      (!context.auth.userData ||
        !context.auth.userData.company ||
        !context.auth.userData.companySize)
    ) {
      throw redirect({
        to: "/complete-profile",
      });
    }
  },
  component: () => <Index />,
});
