import { useContext, useState } from "react";
import { AuthContext } from "./helpers/authContext.tsx";

export default function Troubleshooting() {
  const [isActive, setIsActive] = useState<boolean>(false);

  const { user } = useContext(AuthContext);

  if (!user) {
    return null;
  }

  return (
    <>
      <div
        id="troubleshooting-modal"
        className={`modal ${isActive ? "is-active" : ""}`}
      >
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Troubleshooting</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setIsActive(false)}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="content">
              <h1>Changes Delay</h1>
              <p>
                When you submit a form, the playground immediately flushes the
                changes to Braze servers. However,{" "}
                <b>It can take anywhere from a second to a few minutes</b> for
                their job processing queue to ingest events, depending on the
                overall load at the time, so your action will not have an
                immediate effect on user profile or triggers.
              </p>
              <p>
                The only exception are In-App Messages (IAMs): when you start a
                new session, the Braze SDK downloads and caches locally all IAMs
                for which your user is eligible (in their audience), so any
                trigger action for them will display the IAM immediately. If
                your user becomes eligible for an IAM you just created/updated,
                you might need to reset the session.
              </p>
            </div>
            <div className="content">
              <h1>Ad-blocker extensions</h1>
              <p>
                Most ad-blocker extensions block calls to Braze. If you have
                one, ensure that it is not enabled for this website, or
                whitelist braze.com domain.
              </p>
            </div>
            <div className="content">
              <h1>Notifications</h1>
              <p>
                If notifications are not working, and no warnings is displayed
                on the website, check the following:
              </p>
              <ul>
                <li>
                  Notifications are not instant, unlike in-app messages (see
                  above). You might have to wait a few minutes after the trigger
                  action.
                </li>
                <li>
                  MacOS:
                  <ul>
                    <li>
                      Push notifications in Safari are currently unsupported.
                    </li>
                    <li>
                      Check the Notification Centre. If you see them there, your
                      system settings might silent notifications.
                    </li>
                    <li>
                      Ensure that your browser can send notifications in MacOS
                      System Preferences -&gt; Notifications.
                    </li>
                    <li>
                      If you see the notification banner the first time and not
                      anymore, check the Notification Centre. If you did not
                      dismiss the first notification and the second one has
                      exactly the same content, it might not be displayed as a
                      banner, but is still shown in the Notification Centre.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <div className="navbar-item">
        <button
          className="button is-warning is-small"
          onClick={() => setIsActive(true)}
        >
          <span className="icon is-small">
            <i className="fas fa-bug"></i>
          </span>
          <span>Troubleshooting</span>
        </button>
      </div>
    </>
  );
}
