import { createFileRoute, redirect } from "@tanstack/react-router";
import { Login } from "../components/auth/Login.tsx";

export const Route = createFileRoute("/login")({
  beforeLoad: ({ context }) => {
    if (context.auth.user) {
      throw redirect({
        to: "/",
      });
    }
  },
  component: () => <Login />,
});
