import {
  AuthContext,
  AuthContextProvider,
  User,
} from "./helpers/authContext.tsx";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { useContext } from "react";
import { routeTree } from "./routeTree.gen.ts";
import * as Sentry from "@sentry/react";
import PageNotFound from "./components/PageNotFound.tsx";

const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultNotFoundComponent: () => <PageNotFound />,
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
  },
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

function InnerApp() {
  const auth = useContext<User>(AuthContext);
  return <RouterProvider router={router} context={{ auth }} />;
}

export default function App() {
  return (
    <AuthContextProvider>
      <InnerApp />
    </AuthContextProvider>
  );
}
