import { createFileRoute, redirect } from "@tanstack/react-router";
import { CompleteProfile } from "../components/auth/CompleteProfile.tsx";

export const Route = createFileRoute("/complete-profile")({
  beforeLoad: ({ context }) => {
    if (!context.auth.user) {
      throw redirect({
        to: "/login",
      });
    }
    if (
      context.auth.userData &&
      context.auth.userData.company &&
      context.auth.userData.companySize
    ) {
      throw redirect({
        to: "/",
      });
    }
  },
  component: () => <CompleteProfile />,
});
